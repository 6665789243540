import { ReadLoyaltyBenefitsCardProductVx } from '../constants';

/**
   * Utility function that returns the endpoint for fetchye call based on path
   */
export function getEndpoint(domain, path) {
  if (path === ReadLoyaltyBenefitsCardProductVx) {
    return `${domain}/${ReadLoyaltyBenefitsCardProductVx}`;
  }
  return null;
}
