import { useFetchye } from 'fetchye';
import fetcher from '../api/one-data-fetch-wrapper';

/**
 * @param {string} endpoint - the one data endpoint for fetch request
 * @param {*} options - object to passthrough to fetchye and ES6 fetch
 */
export default function useOneData(endpoint, options) {
  const {
    isLoading,
    data: response,
    error,
    run,
  } = useFetchye(endpoint, options, fetcher);

  const data = response?.body;

  return {
    isLoading,
    data,
    error,
    run,
  };
}
