import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { loadLanguagePack } from '@americanexpress/one-app-ducks';
import oneAppModuleWrapper from '@americanexpress/one-app-module-wrapper';
import { generateUrls } from '@americanexpress/axp-benefits-utils';
import { push } from 'react-router-redux';
import PerformanceMeasure from '@americanexpress/browser-performance-mark';
import {
  selectProduct,
  getSelectedProduct,
  getProductDetailsById,
  getProductsByTypes,
  CARD_PRODUCT,
  BUSINESS_CHECKING_ACCOUNT,
  PERSONAL_CHECKING_ACCOUNT,
} from '@americanexpress/product-ducks';
import { fromJS } from 'immutable';
import {
  isEmpty,
  setSessionCardInformation,
  handleUserSessionAccountTokensOnMount,
  handleDeepLinkUserParams,
  BenefitWrapperPropTypes,
} from '../utils';
import { Body } from './Body';
import { CardDetailsCardProduct } from './CardDetailsCardProduct';
import { CardDetailsBankingProduct } from './CardDetailsBankingProduct';
import { CardDetailsBothProducts } from './CardDetailsBothProducts';

/*
    Calls useOneData to get cardDetails
    check to see what the card is based on the selected token
    selectedProductToken on the state and account token are the same thing
    and validate if a user has access to card product
    selectedProductToken: state has all of the cards that is the selected card in the NAV
    Conditionals in this function are searching for the active card
*/

export const BenefitsWrapper = (props) => {
  const {
    faasApiUrl, login, cardNameFromParams, selectedProductToken, benName,
  } = props;

  const cardProductAccountTokens = !isEmpty(props?.cardProductsList) ? Object.keys(props?.cardProductsList) : [];
  const bankingProductAccountTokens = !isEmpty(props?.bankingProductsList) ? Object.keys(props?.bankingProductsList) : [];

  useEffect(() => {
    handleUserSessionAccountTokensOnMount(login, push, window?.location);
    handleDeepLinkUserParams(props, window?.location);
  }, []);

  useEffect(() => {
    if (login && selectedProductToken) {
      // eslint-disable-next-line camelcase -- needed
      const account_key = props?.selectedProduct?.account_key;
      setSessionCardInformation('sessionCardInformation', { account_key });
    }
  }, [login, selectedProductToken]);

  if (login) {
    if (cardProductAccountTokens?.length > 0 && bankingProductAccountTokens?.length < 1) {
      return (
        <CardDetailsCardProduct
          cardProductAccountTokens={cardProductAccountTokens}
          faasApiUrl={faasApiUrl}
          selectedProductToken={selectedProductToken}
          cardNameFromParams={cardNameFromParams}
          benName={benName}
          // put this back
          // productList={props?.productList}
          // remove these
          cardProductsList={props?.cardProductsList}
          bankingProductsList={props?.bankingProductsList}
          selectProduct={props?.selectProduct}
        >
          { props?.children }
        </CardDetailsCardProduct>
      );
    }
    if (bankingProductAccountTokens?.length > 0 && cardProductAccountTokens?.length < 1) {
      return (
        <CardDetailsBankingProduct
          bankingProductAccountTokens={bankingProductAccountTokens}
          faasApiUrl={faasApiUrl}
          selectedProductToken={selectedProductToken}
          cardNameFromParams={cardNameFromParams}
          benName={benName}
          // put this back
          // productList={props?.productList}
          // remove these
          cardProductsList={props?.cardProductsList}
          bankingProductsList={props?.bankingProductsList}
          selectProduct={props?.selectProduct}
        >
          { props?.children }
        </CardDetailsBankingProduct>
      );
    }
    if (bankingProductAccountTokens?.length > 0 && cardProductAccountTokens?.length > 0) {
      return (
        <CardDetailsBothProducts
          cardProductAccountTokens={cardProductAccountTokens}
          bankingProductAccountTokens={bankingProductAccountTokens}
          faasApiUrl={faasApiUrl}
          selectedProductToken={selectedProductToken}
          cardNameFromParams={cardNameFromParams}
          benName={benName}
          // put this back
          // productList={props?.productList}
          // remove these
          cardProductsList={props?.cardProductsList}
          bankingProductsList={props?.bankingProductsList}
          selectProduct={props?.selectProduct}
        >
          { props?.children }
        </CardDetailsBothProducts>
      );
    }
  }
  return (
    <Body>
      { props?.children }
      <PerformanceMeasure moduleName="axp-benefits-wrapper" variable={true} />
    </Body>
  );
};

BenefitsWrapper.propTypes = BenefitWrapperPropTypes;

BenefitsWrapper.defaultProps = {
  children: null,
  cardNameFromParams: '',
  benName: '',
  login: false,
  selectedProduct: null,
  selectedProductToken: null,
};

export function mapStateToProps(state, ownProps) {
  const env = fromJS(state).getIn(['config', 'localeFilename']);
  const products = getProductsByTypes(state, [CARD_PRODUCT, BUSINESS_CHECKING_ACCOUNT, PERSONAL_CHECKING_ACCOUNT]);
  const selectedProduct = getSelectedProduct(state);
  const cardProductsList = selectedProduct ? products[CARD_PRODUCT]?.productsList : null;
  const businessCheckingProductsList = selectedProduct ? products[BUSINESS_CHECKING_ACCOUNT]?.productsList : null;
  const personalCheckingProductsList = selectedProduct ? products[PERSONAL_CHECKING_ACCOUNT]?.productsList : null;
  const bankingProductsList = { ...businessCheckingProductsList || {}, ...personalCheckingProductsList || {} } || null;
  return {
    cardNameFromParams: ownProps.params.cardname,
    benName: ownProps.params.benName,
    CORPORATE_URL: generateUrls(env).CORPORATE_URL,
    MYCA_LOGIN_URL: generateUrls(env).MYCA_LOGIN_URL,
    CENTURION_LOGIN_URL: generateUrls(env).CENTURION_LOGIN_URL,
    login: Boolean(getSelectedProduct(state)),
    selectedProduct: getProductDetailsById(state, selectedProduct),
    selectedProductToken: getSelectedProduct(state),
    cardProductsList,
    bankingProductsList,
    faasApiUrl: fromJS(state).getIn(['config', 'faasApiUrl']),
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectProduct,
    push,
  }, dispatch);
}

const loadModuleData = ({ store: { dispatch } }) => dispatch(loadLanguagePack('axp-benefits-wrapper', { fallbackLocale: 'en-US' }));

export function shouldModuleReload(currentProps, nextProps) {
  return currentProps?.locale !== nextProps?.locale;
}

const hocChain = compose(
  oneAppModuleWrapper('axp-benefits-wrapper'),
  connect(mapStateToProps, mapDispatchToProps)
);

BenefitsWrapper.holocron = {
  name: 'axp-benefits-wrapper',
  loadModuleData,
  shouldModuleReload,
};

export default hocChain(BenefitsWrapper);
