import PropTypes from 'prop-types';
import { browserHistory } from '@americanexpress/one-app-router';

export const getRoute = (path) => {
  const slicedPath = path.slice(15, 25); // remove /card-benefits/ grab only 10 chars after
  if (slicedPath.includes('view-all')) {
    return 'view-all';
  }
  if (slicedPath.includes('detail')) {
    return 'detail';
  }
  return undefined;
};

export const stripCardNameFromPath = (windowLocation, cardNameToStrip) => {
  const path = windowLocation.pathname;
  const currentURL = windowLocation.href;
  const cardNameWithSlash = `/${cardNameToStrip}`;
  if (getRoute(path) === 'view-all') {
    if (!cardNameToStrip || !path.includes(cardNameToStrip)) {
      return null;
    }
    // replace card names that are not followed by a dash
    // this preserves benefit names that include card name (i.e. 'detail/amex-everyday-2x/amex-everyday')
    const regex = new RegExp(`${cardNameWithSlash}(?!-)`, 'gm');
    return currentURL.replace(regex, '');
  }
  return null;
};

export const getSessionCardInformation = (sessionKey) => {
  const currentSessionCardInformation = window.sessionStorage.getItem(sessionKey);
  // eslint-disable-next-line no-console -- needed
  return Promise.resolve(JSON.parse(currentSessionCardInformation)).catch(console.error);
};

export const setSessionCardInformation = (sessionKey, sessionPayload) => {
  window.sessionStorage.setItem(sessionKey, JSON.stringify(sessionPayload));
};

export const handleUserSessionAccountTokensOnMount = (login, push, windowLocation) => getSessionCardInformation('sessionCardInformation')
  .then((sessionSelectedCardInformation) => {
    const { pathname } = windowLocation;
    if (login && sessionSelectedCardInformation) {
      // Pushes the user to the current path with the appended account_key
      push(`${pathname}?account_key=${sessionSelectedCardInformation.account_key}`);
    }
  });

/*
  Functions for Centurion deep linking
 */
export const getCenturionAccountKey = (props) => {
  const { productList } = props;
  // eslint-disable-next-line guard-for-in,no-restricted-syntax -- needed
  for (const productKey in productList) {
    // eslint-disable-next-line camelcase -- needed
    const { account_token, product: { account_types } } = productList[productKey];
    const lowerCaseAccountTypes = account_types.map((accountType) => accountType.toLowerCase());
    if (lowerCaseAccountTypes.includes('centurion')) {
      // eslint-disable-next-line camelcase -- needed
      return account_token;
    }
  }
  return false;
};

export const isRequestFromCenturionHub = ({ login, productList, cardNameFromParams }) => login && !!productList && cardNameFromParams.includes('centurion');

export const handleDeepLinkUserParams = (props, windowLocation) => {
  const {
    cardNameFromParams,
    push,
    selectProduct,
  } = props;

  if (isRequestFromCenturionHub(props)) {
    const accountToken = getCenturionAccountKey(props);
    selectProduct(accountToken);
    push(stripCardNameFromPath(windowLocation, cardNameFromParams));
  }
};

export const updateSelectedCardAndStrip = (selectProduct, accountToken, cardNameFromParams) => {
  selectProduct(accountToken);
  const newURL = stripCardNameFromPath(window?.location, cardNameFromParams);
  if (newURL) {
    browserHistory.replace(newURL);
  }
};

export const getCardDetailsFromData = (data, accountToken) => {
  return [data.cardDetails.find((cardDetail) => cardDetail.accountToken === accountToken)];
};

export const isEmpty = (val) => {
  if (val === null || typeof val === 'undefined') {
    return true;
  }
  if (typeof val === 'object' && Object.keys(val).length > 0) {
    return false;
  }
  return true;
};

export const BenefitWrapperPropTypes = {
  children: PropTypes.element,
  cardNameFromParams: PropTypes.string,
  benName: PropTypes.string,
  selectProduct: PropTypes.func.isRequired,
  login: PropTypes.bool,
  selectedProduct: PropTypes.shape({
    sorted_index: PropTypes.number,
    account_key: PropTypes.string,
  }),
  selectedProductToken: PropTypes.string,
  push: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};
