import React from 'react';

const Error = () => (
  <div className="alert alert-warn alert-dialog alert-lg" role="alert">
    <span className="alert-icon dls-icon-warning icon-lg" />
    <h1 className="heading-4 alert-header">
      We&apos;re Sorry
    </h1>
    <p className="alert-subtitle">
      Data currently unavailable. Please try again later.
    </p>
    <hr className="alert-separator" />
    <p>If you need immediate assistance, please call the number on the back of your Card.</p>
  </div>
);

export default Error;
