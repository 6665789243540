import React from 'react';
import PropTypes from 'prop-types';

export const Body = ({ children }) => (
  <div data-module-name="axp-benefits-wrapper" className="body" data-testid="body">
    {children}
  </div>
);

Body.propTypes = {
  children: PropTypes.node,
};

Body.defaultProps = {
  children: null,
};

export default Body;
