import { v4 as uuidv4 } from 'uuid';
import isDev from '../utils/is-dev';

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
    'ce-source': 'web.loyalty.benefits-view-all.benefits-dashboard',
  },
  body: {},
  defer: false,
};

/**
 * @param  {function} fetchClient - fetch client to make request
 * @param  {string} endpoint - the endpoint for the fetchClient request
 * @param {*} options - object to passthrough to fetchClient
 * @returns {object} - JSON object representing the payloaad and errors
 */

/**
 * @param  {function} fetchClient - fetch client to make request
 * @param  {string} endpoint - the endpoint for the fetchClient request
 * @param {*} options - object to passthrough to fetchClient
 * @returns {object} - JSON object representing the payloaad and errors
 */

export default async function oneDataFetcher(fetchClient, endpoint, options = {}) {
  let error;
  let ok;
  let notOk;
  let status;
  let body;

  const request = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'one-data-correlation-id': uuidv4(),
      ...defaultOptions.headers,
      ...options.headers,
    },
    body: JSON.stringify({ ...defaultOptions.body, ...options.body }),
    defer: options.default || defaultOptions.default,
  };

  try {
    const response = await fetchClient(endpoint, request);
    ok = response.ok;
    notOk = !response.ok;
    status = response.status;

    if (notOk) {
      throw response;
    }

    body = await response.json();

    /* istanbul ignore next */
    if (isDev()) {
      // console.group(`One Data Response from ${endpoint}`);
      // console.group('Response');
      // console.log(body);
      // console.groupEnd();
    }
  } catch (caughtError) {
    /* istanbul ignore next */
    if (isDev()) {
      // eslint-disable-next-line no-console -- needed
      console.error(`one data fetcher threw the error: ${caughtError}`);
    }
    error = caughtError;
  }

  return {
    payload: {
      ok,
      status,
      body,
    },
    error,
  };
}
