/* eslint complexity: ["error", 14] -- needed */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from '@americanexpress/one-app-router';
import PerformanceMeasure from '@americanexpress/browser-performance-mark';
import Error from './Error';
import { Body } from './Body';
import useOneData from '../hooks/useOneDataCall';
import { getEndpoint } from '../api/api-utils';
import { ReadLoyaltyBenefitsCardProductVx } from '../constants';
import {
  getCardDetailsFromData,
  stripCardNameFromPath,
  updateSelectedCardAndStrip,
  isEmpty,
} from '../utils';
/*
    Calls useOneData to get cardDetails from ReadLoyaltyBenefitsCardProduct
    Handles redirecting, product selection update (based on card name in url), and card name stripping
    Passes cardDetails prop down to axp-benefits-view-all
*/

export const CardDetailsBankingProduct = (props) => {
  const { bankingProductAccountTokens } = props;

  // make api call
  const endpoint = getEndpoint(props?.faasApiUrl, ReadLoyaltyBenefitsCardProductVx);

  const endpointOptions = (accountTokens) => {
    return {
      body: {
        accountTokens,
        cardNames: [],
      },
    };
  };

  const { isLoading: cardIsLoading, data: cardData, error: cardError } = useOneData(endpoint, endpointOptions(bankingProductAccountTokens));

  const data = {};

  // explicit checking of loaded condition based on tokens
  if (!cardIsLoading) {
    data.cardDetails = [];
    cardData?.cardDetails.forEach((card) => data?.cardDetails.push(card));
  }

  const isFirstRender = useRef(true);
  let currentCardDetails;
  let preRenderSetupComplete = false;

  const preRenderSetup = () => {
    let accountTokenToUse = props?.selectedProductToken;
    // override account switcher product selection from card name in url
    // only do this on arrival/first render so that users are free to select a new product once first render is complete
    if (isFirstRender.current) {
      // create array of all card names in wallet with their corresponding account tokens
      const cardNamesAndTokens = [
        ...data.cardDetails.map((card) => (
          {
            cardName: card.cardName,
            accountToken: card.accountToken,
          })),
      ];
      // check if the card name in the url is available in their wallet
      const cardNameFromParamsAccountToken = cardNamesAndTokens.find((card) => card.cardName === props?.cardNameFromParams)?.accountToken;
      // if so, and it's not already selected in the account switcher - select the new card and use its account token to gather view-all props
      if (cardNameFromParamsAccountToken && cardNameFromParamsAccountToken !== props?.selectedProductToken) {
        updateSelectedCardAndStrip(props?.selectProduct, cardNameFromParamsAccountToken, props?.cardNameFromParams);
        // override default token so the correct props are passed into view-all
        accountTokenToUse = cardNameFromParamsAccountToken;
      }
    }
    // get card details to pass to view-all
    currentCardDetails = getCardDetailsFromData(data, accountTokenToUse)[0];

    // do redirect specified for this card, if applicable
    const redirectURL = currentCardDetails?.redirectURL;
    if (redirectURL) {
      window.location.assign(redirectURL);
    }
    // do card name stripping if card name is in url and matches the current selected card (view-all only)
    if (props?.cardNameFromParams && currentCardDetails?.accountToken === accountTokenToUse) {
      const newURL = stripCardNameFromPath(window?.location, props?.cardNameFromParams);
      if (newURL) {
        browserHistory.replace(newURL);
      }
    }
    preRenderSetupComplete = true;
    isFirstRender.current = false;
  };

  if (!isEmpty(data?.cardDetails)) {
    preRenderSetup();
  }

  // set conditions for state to render
  const renderIsLoading = !data && cardIsLoading;
  const renderErrorState = !renderIsLoading && !!cardError;
  const renderChildComponents = !renderIsLoading && !renderErrorState && preRenderSetupComplete;

  return (
    <>
      {cardIsLoading && (
        <Body>
          <div className="flex flex-justify-center">
            <div className="progress-circle progress-indeterminate progress-lg" />
          </div>
        </Body>
      )}
      {renderErrorState && (
        <Body>
          <Error />
          <PerformanceMeasure moduleName="axp-benefits-wrapper" variable={true} />
        </Body>
      )}
      {renderChildComponents && (
        <Body>
          {React.Children.map(
            props?.children, (child) => React.cloneElement(child, { cardDetails: currentCardDetails })
          )}
          <PerformanceMeasure moduleName="axp-benefits-wrapper" variable={true} />
        </Body>
      )}
    </>
  );
};

CardDetailsBankingProduct.propTypes = {
  bankingProductAccountTokens: PropTypes.arrayOf(PropTypes.string),
  faasApiUrl: PropTypes.string.isRequired,
  childrenRender: PropTypes.shape({}),
  cardNameFromParams: PropTypes.string.isRequired,
  selectedProductToken: PropTypes.string,
  cardProductsList: PropTypes.shape({}),
  bankingProductsList: PropTypes.shape({}),
  selectProduct: PropTypes.func,
};

export default CardDetailsBankingProduct;
