import ModuleContainer from './components/BenefitsWrapper';

export default ModuleContainer;
;
if (!global.BROWSER) {
  ModuleContainer.appConfig = Object.assign({}, ModuleContainer.appConfig, {
    appCompatibility: "^5.0.0",
  });
}
;
if (!global.BROWSER) {
  ModuleContainer.appConfig = Object.assign({}, ModuleContainer.appConfig, {
    requiredExternals: {
      '@americanexpress/one-app-module-wrapper': '^3.4.5',
      '@americanexpress/product-ducks': '^1.3.3',
      'iguazu': '^3.0.2',
      'iguazu-rpc': '^1.5.0',
      'iguazu-holocron': '^1.1.0',
      'iguazu-rest': '^1.8.0',
    },
  });
}
